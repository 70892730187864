import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import axios from 'axios';
import {
  generateReport,
  getServicePrograms,
} from '../../shared/reportGenerate';
import '../PendingRequest/toggle.css';
import { toast } from 'react-toastify';
import { Accordion, Modal } from 'react-bootstrap';
import secureLocalStorage from 'react-secure-storage';
import { Icon } from '@iconify/react';
import DecodeVechicle from './Vechical/DecodeVehical';
import CustomerInformation from './Customer/CustomerInformation';
import Offers from './Offers/Offers';
import { apiGetRequest } from '../../../helpers/Requests';
import {
  URL_ADMIN_GET_MANAGER_BY_DEALER_ID,
  URL_ADMIN_SERVICE_TENURES_LIST,
  URL_ADMIN_SERVICE_TIERS_LIST,
  URL_MANAGER_VERIFIED_LIST,
} from '../../../service/URL';
import CustomSelect from '../../common/Select/CustomSelect';
import {
  capitalizeAllFirstLetters,
  capitalizeFirstLetter,
  classNames,
  convertDateByTimezone,
  downloadFile,
  setSericeTenureOptions,
  sumOfProgramsPrices,
} from '../../../utils/utils';
import './addRegistrant.css';
import _ from 'lodash';
import {
  extractDetails,
  findTrueValuesByCatId,
  groupByServiceCategoryId,
  preprocessCategoriesForQuickAccess,
} from './registrantFunctions';
import CustomButton from '../../common/Button/CustomButton';
import PrintRegistration from './PDF/PrintRegistrant';
import { POSTALCODE_PATTERN, USER_ROLE } from '../../../utils/constant';
import moment from 'moment';
import useDealer from '../../../Hooks/useDealer';
import ServicePricingSummary from './servicePricingSummary/ServicePricingSummary';
import { FaPrint } from 'react-icons/fa';
import ScrollToTop from '../../../Hooks/scrollToTop';
import RegistrationContext from './registrationContext/registrationContext';
import AuthContext from '../../../helpers/authContext';
import {PDF_URL} from '../../../service/URL';

const AddRegistrants = (props) => {
  ScrollToTop();

  const { timezone } = useContext(AuthContext);

  const {
    setShowEditRegistrants,
    setEditReport,
    editReport = null,
    setActiveTab,
    setCounter,
    dealerObj,
    managerObj,
  } = props;

  const downloadRef = useRef();

  const [role] = useState(JSON.parse(secureLocalStorage.getItem('Role')));

  //For Edit Report
  const [selectedReason, setSelectedReason] = useState('');

  // Manager
  const [isFetchManagerListLoader, setIsFetchManagerListLoader] =
    useState(false);
  const [managerListOptions, setManagerListOptions] = useState([]);
  const [selectedManager, setSelectedManager] = useState(null);

  //modals
  const [showmodal, setShowmodal] = useState(false);
  const [showmodalDraft, setShowModalDraft] = useState(false);

  //GST & PST
  const [gst, setGst] = useState(5);
  const [pst, setPst] = useState(0);

  // Customer
  const [customer, setCustomer] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    state: '',
    postalCode: '',
  });
  const [customerError, setCustomerError] = useState({
    firstName: false,
    lastName: false,
    email: false,
    phone: false,
    address: false,
    city: false,
    state: false,
    postalCode: false,
  });

  //Vehicle
  const [vehicleVin, setvehicleVin] = useState();
  const [vehical, setVehical] = useState({
    vin: '',
    make: '',
    modal: '',
    // purchaseDate: "",
    type: '',
    year: '',
    productType: '',
    vehicalType: '',
    odometerReading: 0,
    isPowerSport: false,
    vehicalTypeNote: '',
  });
  const [vehicalError, setVehicalError] = useState({
    vin: false,
    make: false,
    modal: false,
    // purchaseDate: false,
    type: false,
    year: false,
    productType: false,
    odometerReading: false,
  });

  //Services Programs
  const [serviceCatPrograms, setServiceCaPrograms] = useState([]);
  const [isFetchSerciceCatPrograms, setIsFetchSerciceCatPrograms] =
    useState(false);

  // service Tier
  const [isFetchSericeTier, setIsFetchServiceTier] = useState(false);
  const [serviceTierOptions, setServiceTierOptions] = useState([]);

  //service Tenure
  const [isFetchServiceTenureLoader, setIsFetchServiceTenureLoader] =
    useState(false);
  const [serviceTenureOptions, setServiceTenureOptions] = useState([]);

  //Checkboxes Services An AddOns
  const [checkBoxesForTerms, setCheckBoxesForTerms] = useState({});
  const [checkBoxesForServices, setCheckBoxesForServices] = useState({});

  //Dealer Options
  const { isDealerListLoader, dealersData = [] } = useDealer();
  const [dealerOptions, setDealerOptions] = useState([]);
  const [selectedDealerOption, setSelectedDealerOption] = useState(null);

  // Is power sport

  useEffect(() => {
    if (dealersData?.length > 0) {
      setDealerOptions(
        dealersData?.map((x) => ({
          ...x,
          label:
            x?.name || " ",
            // x?.firstName && x.firstName?.length > 0
            //   ? `${x.firstName} ${x.lastName}`
            //   : x?.name || '',
          value: x._id,
        }))
      );
    }
  }, [dealersData]);

  useEffect(() => {
    if (
      selectedDealerOption &&
      selectedDealerOption?.value &&
      role === USER_ROLE.ADMIN
    ) {
      handleGetVerifiedManagers();
      setSelectedManager(null);
      setManagerListOptions([]);
      setServiceCaPrograms([]);
    } else if (
      role === USER_ROLE.STAFF.SERVICE_ADVISOR &&
      selectedDealerOption?.value
    ) {
      handleManagerChange();
    }
  }, [selectedDealerOption]);

  useEffect(() => {
    if (role === USER_ROLE.DEALER) {
      handleGetVerifiedManagers();
    }
    getServiceTiers();
    getServiceTenures();

    return () => {
      setCreatedData(null);
    };
  }, []);

  useEffect(() => {
    if (role === USER_ROLE.MANAGER && managerObj) {
      setSelectedManager({ ...managerObj, value: managerObj._id });
      handleManagerChange();
    }
  }, [role, managerObj]);

  const getServiceTenures = async () => {
    try {
      setIsFetchServiceTenureLoader(true);
      const response = await apiGetRequest(URL_ADMIN_SERVICE_TENURES_LIST);
      if (response.data && response.data.tenures) {
        setServiceTenureOptions(setSericeTenureOptions(response.data.tenures));
      }
      setIsFetchServiceTenureLoader(false);
    } catch (err) {
      setIsFetchServiceTenureLoader(false);
      console.log('err', err);
    }
  };

  const getServiceTiers = async () => {
    try {
      setIsFetchServiceTier(true);
      const response = await apiGetRequest(URL_ADMIN_SERVICE_TIERS_LIST);
      if (response.data && response.data.tiers) {
        setServiceTierOptions(
          response.data.tiers.map((x) => ({
            ...x,
            label: x.tierName,
            value: x._id,
          }))
        );
      }
      setIsFetchServiceTier(false);
    } catch (err) {
      setIsFetchServiceTier(false);
      console.log('err', err);
    }
  };

  const handleGetVerifiedManagers = async () => {
    try {
      setIsFetchManagerListLoader(true);
      const response = await apiGetRequest(
        role === USER_ROLE.ADMIN
          ? `${URL_ADMIN_GET_MANAGER_BY_DEALER_ID}/${selectedDealerOption.value}`
          : URL_MANAGER_VERIFIED_LIST
      );
      const data =
        role === USER_ROLE.ADMIN ? response.data : response.data?.managers;
      if (data) {
        setManagerListOptions(
          data?.map((x) => ({
            ...x,
            label:
              x?.firstName && x.firstName?.length > 0
                ? capitalizeAllFirstLetters(`${x.firstName} ${x.lastName}`)
                : capitalizeAllFirstLetters(x?.name) || '',
            value: x._id,
          }))
        );
        //Fetch All Services And AddOns
        if (
          role === USER_ROLE.ADMIN &&
          selectedDealerOption &&
          selectedDealerOption.value
        ) {
          handleManagerChange();
        } else {
          handleManagerChange();
        }
      }
      setIsFetchManagerListLoader(false);
      if (role === USER_ROLE.ADMIN && editReport !== null) {
        setSelectedManager({
          ...editReport,
          label:
            editReport?.manager?.firstName &&
            editReport?.manager.firstName?.length > 0
              ? capitalizeAllFirstLetters(
                  `${editReport?.manager.firstName} ${editReport?.manager.lastName}`
                )
              : capitalizeAllFirstLetters(editReport?.manager?.name) || '',
          value: editReport?.manager._id,
        });
      }
    } catch (err) {
      setIsFetchManagerListLoader(false);
      console.log('err', err);
    }
  };

  const [isSubmitApiLoader, setIsSubmitApiLoader] = useState(false);

  const [createdData, setCreatedData] = useState(null);

  const ValidationCustomerForm = () => {
    let errorObj = {
      firstName: false,
      lastName: false,
      email: false,
      phone: false,
      address: false,
      city: false,
      state: false,
      postalCode: false,
    };

    if (customer.firstName === '' || customer.firstName === undefined) {
      setCustomerError((prev) => ({
        ...prev,
        firstName: true,
      }));
      errorObj.firstName = true;
    }
    if (customer.lastName === '' || customer.lastName === undefined) {
      setCustomerError((prev) => ({
        ...prev,
        lastName: true,
      }));
      errorObj.lastName = true;
    }
    if (customer.email === '' || customer.email === undefined) {
      setCustomerError((prev) => ({
        ...prev,
        email: true,
      }));
      errorObj.email = true;
    }
    if (customer.address === '' || customer.address === undefined) {
      setCustomerError((prev) => ({
        ...prev,
        address: true,
      }));
      errorObj.address = true;
    }
    if (customer.city === '' || customer.city === undefined) {
      setCustomerError((prev) => ({
        ...prev,
        city: true,
      }));
      errorObj.city = true;
    }

    if (customer.postalCode === '' || customer.postalCode === undefined) {
      setCustomerError((prev) => ({
        ...prev,
        postalCode: true,
      }));
      errorObj.postalCode = true;
    }
    if (
      customer.phone?.trim() === '+1' ||
      customer.phone === '' ||
      customer.phone === undefined
    ) {
      setCustomerError((prev) => ({
        ...prev,
        phone: true,
      }));
      errorObj.phone = true;
    }
    if (
      customer.postalCode?.length > 0 &&
      !POSTALCODE_PATTERN.test(customer.postalCode?.trim())
    ) {
      setCustomerError((prev) => ({
        ...prev,
        postalCode: true,
      }));
      errorObj.postalCode = true;
    }

    if (customer.state === '' || customer.state === undefined) {
      setCustomerError((prev) => ({
        ...prev,
        state: true,
      }));
      errorObj.state = true;
    }
    return errorObj;
  };

  const ValidationVehicleForm = () => {
    let errorObj = {
      vin: false,
      make: false,
      // purchaseDate: false,
      modal: false,
      type: false,
      year: false,
      productType: false,
      odometerReading: false,
    };
    if (vehical?.vin === '' || vehical === undefined) {
      setVehicalError((prev) => ({
        ...prev,
        vin: true,
      }));
      errorObj.vin = true;
    }
    if (vehical?.make === '' || vehical?.make === undefined) {
      setVehicalError((prev) => ({
        ...prev,
        make: true,
      }));
      errorObj.make = true;
    }
    // else if (vehical.purchaseDate === '' || vehical.purchaseDate === undefined) {
    //   setVehical((prev) => ({
    //     ...prev,
    //     purchaseDate: true
    //   }))
    //   errorObj.purchaseDate = true
    // }
    if (vehical?.modal === '' || vehical?.modal === undefined) {
      setVehicalError((prev) => ({
        ...prev,
        modal: true,
      }));
      errorObj.modal = true;
    }
    if (vehical?.category === '' || vehical?.category === undefined) {
      setVehicalError((prev) => ({
        ...prev,
        category: true,
      }));
      errorObj.category = true;
    }
    if (vehical?.year === '' || vehical?.year === undefined) {
      setVehicalError((prev) => ({
        ...prev,
        year: true,
      }));
      errorObj.year = true;
    }
    if (vehical?.productType === '' || vehical?.productType === undefined) {
      setVehicalError((prev) => ({
        ...prev,
        productType: true,
      }));
      errorObj.productType = true;
    }
    if (
      vehical?.hasOwnProperty('odometerReading') === false ||
      vehical?.odometerReading === '' ||
      vehical?.odometerReading === undefined ||
      vehical?.odometerReading === 0
    ) {
      setVehicalError((prev) => ({
        ...prev,
        odometerReading: true,
      }));
      errorObj.odometerReading = true;
    }
    return errorObj;
  };

  const handleSubmit = () => {
    const UserID = JSON.parse(secureLocalStorage.getItem('UserID'));
    const customerValid = ValidationCustomerForm();
    const vehicalValid = ValidationVehicleForm();

    if (
      customerValid.firstName ||
      customerValid.lastName ||
      customerValid.email ||
      customerValid.address ||
      customerValid.city ||
      customerValid.postalCode ||
      customerValid.state ||
      customerValid.phone
    ) {
      console.log('Customer Form has validation errors', customerValid);
      const errorKey =
        Object.entries(customerValid).filter(([key, value]) => value === true)
          .length > 0
          ? Object.entries(customerValid).filter(
              ([key, value]) => value === true
            )[0]?.[0]
          : null;
      if (errorKey !== null) {
        toast.error(`Customer ${capitalizeFirstLetter(errorKey)} is required!`);
      }
      return;
    }
    if (
      vehicalValid.vin ||
      vehicalValid.make ||
      vehicalValid.modal ||
      vehicalValid.year ||
      vehicalValid.productType ||
      vehicalValid.type ||
      vehicalValid.odometerReading
    ) {
      const errorKey =
        Object.entries(vehicalValid).filter(([key, value]) => value === true)
          .length > 0
          ? Object.entries(vehicalValid).filter(
              ([key, value]) => value === true
            )[0]?.[0]
          : null;
      if (errorKey !== null) {
        toast.error(`Vehicle ${capitalizeFirstLetter(errorKey)} is required!`);
      }
      return;
    }

    if (
      vehical?.vin === '' ||
      vehical?.vin === null ||
      vehical?.vin === undefined
    ) {
      toast.error('Please Enter Vehicle Vin!');
      return;
    }

    // if (vehical?.make) {
    //   const tier = serviceTierOptions.find((tier) =>
    //     tier.match.includes(vehical?.make.toUpperCase())
    //   );
    //   if (!tier) {
    //     toast.error("The vehicle doesn't fall in any tier!");
    //     return;
    //   }
    // }

    if (selectedManager === null) {
      toast.info('Please Select Manager!');
      return;
    }

    const formData = {
      firstName: customer.firstName,
      lastName: customer.lastName,
      email: customer.email,
      address: customer.address,
      city: customer.city,
      postalCode: customer.postalCode,
      phone: customer.phone,
      state: customer.state,
      unitNumber: customer.unitNumber ?? '',
      company: customer.company ?? '',
      coApplicant: customer.coApplicant ?? '',
      lienholder: customer.lienholder ?? '',
      customerComment: customer.customerComment ?? '',
      gst: Number(gst),
      pst: Number(pst),
      vehical: {
        ...vehical,
        purchaseDate: vehical.purchaseDate
          ? moment(vehical.purchaseDate).format('MMMM DD, YYYY')
          : moment().format('MMMM DD, YYYY'),
        year: moment.utc(vehical.year).format('YYYY'),
      },
    };

    if (editReport !== null && editReport.customer._id) {
      Object.assign(formData, {
        customerId: editReport.customer._id,
      });
    }

    let dealerId = '';
    if (role === USER_ROLE.MANAGER) {
      dealerId = selectedManager.dealer.hasOwnProperty('_id')
        ? selectedManager.dealer?._id
        : selectedManager.dealer;
    } else if (role === USER_ROLE.DEALER) {
      dealerId = UserID;
    } else if (role === USER_ROLE.ADMIN) {
      dealerId = selectedDealerOption.value;
    } else {
      return;
    }

    const managerId = selectedManager && selectedManager?.value;

    if (!dealerId || !managerId) {
      toast.info('Dealer And Manager is required!');
      return;
    }

    // console.log(
    //   dealerId,
    //   managerId,
    //   checkBoxesForTerms,
    //   checkBoxesForServices,
    //   {
    //     ...formData,
    //     dealerId: dealerId,
    //   },
    //   structuredData,
    //   false, //Is Draft Check
    //   editReport !== null ? editReport._id : null,
    //   selectedReason
    // );
    // return;
    setIsSubmitApiLoader(true);

    generateReport(
      dealerId,
      managerId,
      checkBoxesForTerms,
      checkBoxesForServices,
      {
        ...formData,
        dealerId: dealerId,
      },
      structuredData,
      false, //Is Draft Check
      editReport !== null ? editReport._id : null,
      selectedReason
    )
      .then((res) => {
        setCreatedData(res?.data);
        toast.success(
          `Registrant ${
            editReport !== null ? 'Updated' : 'Added'
          } Successfully!`
        );
        setShowmodal(true);
        setIsSubmitApiLoader(false);
      })
      .catch((err) => {
        // console.log(err);
        toast.error(err?.message ? err?.message : 'Something Went Wrong!');
        setIsSubmitApiLoader(false);
      });
  };

  const [isFirstCallForServiceProgram, setIsFirstCallForServiceProgram] =
    useState(-1);

  const handleManagerChange = async (id) => {
    try {
      if (isFirstCallForServiceProgram !== -1 && role !== USER_ROLE.ADMIN) {
        return;
      }
      setIsFirstCallForServiceProgram(1);
      setIsFetchSerciceCatPrograms(true);
      const data = await getServicePrograms(role, selectedDealerOption?.value);
      setIsFirstCallForServiceProgram(0);
      setServiceCaPrograms(
        data.categories.map((x) => ({
          ...x,
          purchaseDate: moment().format('YYYY-MM-DD'),
          retailerPrice: 0,
        })) || []
      );
      setIsFetchSerciceCatPrograms(false);
    } catch (err) {
      setIsFetchSerciceCatPrograms(false);
      console.log('err', err);
    }
  };

  const [isPricingVisible, setIsPricingVisible] = useState(false);

  const handleToggleChange = () => {
    setIsPricingVisible(!isPricingVisible);
  };

  const setAgreeDateAndRetialAndTheifId = () => {
    const details = editReport.details ? JSON.parse(editReport.details) : [];
    const prevData = _.cloneDeep(serviceCatPrograms);
    if (prevData) {
      details.forEach((dataItem) => {
        const index = prevData.findIndex((x) => x._id === dataItem.categoryId);
        if (index !== -1) {
          prevData[index] = {
            ...prevData[index],
            purchaseDate: dataItem.purchaseDate,
            retailerPrice: dataItem.retailerPrice,
            antitheft: dataItem.antitheft,
            tiresize: dataItem.tiresize,
          };
        }
      });
      setServiceCaPrograms([...prevData]);
      setIsAddField(true);
    }
  };
  const [isAddFeilds, setIsAddField] = useState(false);

  const quickAccessMap = useMemo(() => {
    return preprocessCategoriesForQuickAccess(serviceCatPrograms);
  }, [serviceCatPrograms]);

  const [structuredData, setStructuredData] = useState([]);

  useEffect(() => {
    if (
      serviceCatPrograms?.length > 0 &&
      editReport !== null &&
      isAddFeilds === false
    ) {
      setAgreeDateAndRetialAndTheifId();
    }
  }, [structuredData]);

  // Handle Checkbox Change For Terms
  const handleUnCheckedOtherTerms = (
    catId,
    tenureId,
    isChecked,
    typeOfEvent
  ) => {
    const prevData = _.cloneDeep(checkBoxesForTerms);
    if (typeOfEvent === 'isFirstRender') {
      prevData[`${catId}-${tenureId}`] = isChecked;
      setCheckBoxesForTerms({ ...prevData });
      return;
    }
    const prevTrueKey = findTrueValuesByCatId(prevData, catId);
    const prevDataForServices = _.cloneDeep(checkBoxesForServices);
    prevData[`${catId}-${tenureId}`] = isChecked;

    const areAllCatIdValuesFalse = Object.entries(prevData)
      .filter(([key, _]) => key.startsWith(`${catId}-`))
      .every(([_, value]) => value === false);

    Object.entries(prevData).forEach(([key, value]) => {
      if (prevTrueKey) {
        delete prevData[prevTrueKey];
      }
      if (key !== `${catId}-${tenureId}`) {
        const [cId, tId] = key.split('-');
        if (catId === cId) {
          prevData[`${key}`] = false;
          Object.entries(prevDataForServices).forEach(([serviceKey, val]) => {
            if (serviceKey.startsWith(`${catId}-${tenureId}-`)) {
              delete prevDataForServices[serviceKey];
            }
          });
        }
        delete prevData[prevTrueKey];
      } else {
        if (areAllCatIdValuesFalse) {
          Object.entries(prevDataForServices).forEach(([serviceKey, val]) => {
            if (serviceKey.startsWith(`${catId}-${tenureId}-`)) {
              delete prevDataForServices[serviceKey];
            }
          });
        } else if (prevTrueKey) {
          Object.entries(prevDataForServices).forEach(([serviceKey, value]) => {
            if (serviceKey.startsWith(`${prevTrueKey}-`)) {
              const [cId, tId, pId, PrcId, tierId] = serviceKey.split('-');
              const result = quickAccessMap[`${catId}-${tenureId}`] || [];
              if (result && result.length > 0) {
                result.forEach((resultItem) => {
                  let newKey = `${catId}-${tenureId}-${resultItem?.newProgramId}-${resultItem?.newPriceId}`;
                  if (
                    tierId !== null &&
                    tierId !== undefined &&
                    resultItem?.newServiceTierId !== undefined &&
                    resultItem?.newServiceTierId !== null
                  ) {
                    newKey = `${catId}-${tenureId}-${resultItem?.newProgramId}-${resultItem?.newPriceId}-${resultItem?.newServiceTierId}`;
                  }
                  if (
                    tierId !== null &&
                    tierId !== undefined &&
                    resultItem?.newProgramId === pId &&
                    resultItem?.newServiceTierId === tierId
                  ) {
                    prevDataForServices[newKey] = value === true ? true : false;
                    if (newKey !== serviceKey) {
                      delete prevDataForServices[serviceKey];
                    }
                  } else if (tierId === null || tierId === undefined) {
                    if (resultItem?.newProgramId === pId) {
                      prevDataForServices[newKey] =
                        value === true ? true : false;
                      if (newKey !== serviceKey) {
                        delete prevDataForServices[serviceKey];
                      }
                    }
                  }
                });
              }
            }
          });
        }
      }
    });

    setCheckBoxesForTerms({ ...prevData });
    setCheckBoxesForServices({ ...prevDataForServices });
  };

  const handleCheckedServicesByTenure = (
    catId,
    programId,
    tenureId,
    priceId,
    isChecked,
    isTierId = null
  ) => {
    const prevData = _.cloneDeep(checkBoxesForServices);
    if (isChecked === false) {
      if (isTierId !== null && isTierId !== false) {
        delete prevData[
          `${catId}-${tenureId}-${programId}-${priceId}-${isTierId}`
        ];
      } else {
        delete prevData[`${catId}-${tenureId}-${programId}-${priceId}`];
      }
    } else {
      if (isTierId !== null && isTierId !== false) {
        prevData[`${catId}-${tenureId}-${programId}-${priceId}-${isTierId}`] =
          isChecked;
      } else {
        prevData[`${catId}-${tenureId}-${programId}-${priceId}`] = isChecked;
      }
    }
    setCheckBoxesForServices({ ...prevData });
  };

  useEffect(() => {
    const groupByServiceCategoryData = groupByServiceCategoryId(
      checkBoxesForTerms,
      checkBoxesForServices
    );
    const extractedDetails = extractDetails(
      groupByServiceCategoryData,
      serviceCatPrograms,
      serviceTenureOptions,
      serviceTierOptions
    );
    // If in extractedDeatils array find item that item.categoryId == "65de06875a4edc7aca3a08b1"
    const oilChangePackageIds = ["65de06875a4edc7aca3a08b1", "66d9083eecefd5bd3e721291"];
    const vehicleMakeArray = ["buick", "gmc", "ram", "ford", "cadillac", "jeep", "chrysler", "lincoln"];

    if (extractedDetails.some(item => oilChangePackageIds.includes(item.categoryId)) && !vehicleMakeArray.includes(vehical.make.toLowerCase())) {
      const updatedExtractedDetails = extractedDetails.map(item => {
        if (oilChangePackageIds.includes(item.categoryId)) {
          item.programs = item.programs.filter(program => !program.programName.toLowerCase().includes("gm") && !program.programName.toLowerCase().includes("stellantis"));
        }
        return item;
      });
      setStructuredData([...updatedExtractedDetails]);
    } else {
      setStructuredData([...extractedDetails]);
    }
    
  }, [checkBoxesForTerms, checkBoxesForServices, serviceCatPrograms, vehical]);

  const handleInputServicesCatDetails = (e, catId) => {
    const prevData = _.cloneDeep(serviceCatPrograms);
    if (prevData) {
      const index = prevData.findIndex((x) => x._id === catId);
      if (index !== -1) {
        prevData.splice(index, 1, {
          ...prevData[index],
          [e.target.name]: e.target.value,
        });
      }
      setServiceCaPrograms([...prevData]);
    }
  };

  useEffect(() => {
    if (editReport !== null) {
      setCheckBoxesForTerms(editReport.services);
      setCheckBoxesForServices(editReport.addOns);
      const veh = editReport.customer.vehical;
      setVehical(veh);
      delete customer.vehical;
      setCustomer(editReport.customer);
      setSelectedManager({
        ...editReport.manager,
        label:
          editReport?.manager?.firstName &&
          editReport?.manager.firstName?.length > 0
            ? capitalizeAllFirstLetters(
                `${editReport?.manager.firstName} ${editReport?.manager.lastName}`
              )
            : capitalizeAllFirstLetters(editReport?.manager?.name) || '',
        value: editReport?.manager._id,
      });
      setSelectedDealerOption({
        ...editReport.dealer,
        label:
          editReport?.dealer?.firstName &&
          editReport?.dealer.firstName?.length > 0
            ? capitalizeAllFirstLetters(
                `${editReport?.dealer.firstName} ${editReport?.dealer.lastName}`
              )
            : capitalizeAllFirstLetters(editReport?.dealer?.name) || '',
        value: editReport?.dealer._id,
      });
      setSelectedReason(editReport?.updateReason || '');
      setPst(editReport?.customer?.pst || 0);
      setGst(editReport?.customer?.gst);

      // console.log(
      //   "JSON.parse(editReport?.details)",
      //   JSON.parse(editReport?.details)
      // );
    }
  }, [editReport]);

  const calculateExpiryDate = (purchaseDate, tenureDuration) => {
    const duration = parseInt(tenureDuration.split(' ')[0], 10);
    const purchase = new Date(purchaseDate);
    purchase.setMonth(purchase.getMonth() + duration);

    return purchase.toISOString().split('T')[0];
  };

  const [pdfDownloadLoader, setPdfDownloadLoader] = useState(false);

  const generatePDF = async () => {
    try {
      // handlePrintFn();
      // handlePrint();
      // if (downloadRef?.current) {
      //   const printContent = downloadRef.current.innerHTML;
      //   const printWindow = window.open("", "", "height=600,width=800");
      //   printWindow.document.write(
      //     "<html><head><title>Registrant Print</title>"
      //   );
      //   const cssLink = printWindow.document.createElement("link");
      //   cssLink.rel = "stylesheet";
      //   cssLink.href = "https://s3.us-west-2.amazonaws.com/extendedtrustcdn/pdfRegistrant.css";
      //   cssLink.type = "text/css";
      //   cssLink.media = "print";

      //   cssLink.onload = () => {
      //     printWindow.document.close();
      //     // printWindow.focus(); // For IE >= 10
      //     // printWindow.print();
      //     // printWindow.close();
      //   };

      //   printWindow.document.head.appendChild(cssLink);

      //   printWindow.document.write("</head><body>");
      //   printWindow.document.write(printContent);
      //   printWindow.document.write("</body></html>");
      //   printWindow.document.close();

      //   printWindow.onload = function () {
      //     printWindow.focus(); // For IE >= 10
      //     printWindow.print();
      //     printWindow.close();
      //   };
      // }
      // console.log("print PDF");

      const services = structuredData?.map((service) => {
        if (service.programs) {
          service.programs.forEach((program) => {
            if (program.priceDetails && program.priceDetails.serviceTenure) {
              const tenureDuration =
                program.priceDetails.serviceTenure.tenureDuration;
              const purchaseDate = service.purchaseDate;
              program.priceDetails.serviceTenure.tenureExpiry =
                calculateExpiryDate(purchaseDate, tenureDuration);
            }
          });
        }
        return service;
      });

      const allServices = services
        .map((service) => service?.categoryName)
        .join(', ');

      const subtotalRetail = services?.reduce((acc, item) => {
        const retailerPrice =
          Number(item.retailerPrice) || sumOfProgramsPrices(item.programs);
        const programPrices = Number(sumOfProgramsPrices(item.programs));
        const total = retailerPrice !== 0 ? retailerPrice : programPrices;
        return acc + total;
      }, 0);

      const dataForPrint = createdData !== null ? createdData : editReport;

      // console.log("dataForPrint", dataForPrint);

      const retailTotal = Number(subtotalRetail?.toFixed(2));
      const retailGST = Number(
        ((subtotalRetail || 0) * (dataForPrint?.customer?.gst / 100))?.toFixed(
          2
        )
      );

      const retailPST = Number(
        (subtotalRetail || 0) * ((dataForPrint?.customer?.pst || 0) / 100)
      )?.toFixed(2);
      const retailGrand = Number(
        (
          (subtotalRetail || 0) +
          (subtotalRetail || 0) * ((dataForPrint?.customer?.pst || 0) / 100) +
          (subtotalRetail || 0) * (dataForPrint?.customer?.gst / 100)
        )?.toFixed(2)
      );

      const payload = {
        services,
        customer: dataForPrint?.customer || null,
        vehicle: dataForPrint?.customer?.vehical
          ? {
              ...dataForPrint?.customer?.vehical,
              purchaseDate: moment(
                convertDateByTimezone(
                  dataForPrint?.customer?.vehical?.purchaseDate,
                  timezone
                )
              ).format('MMMM DD, YYYY'),
              year: moment
                .utc(dataForPrint?.customer?.vehical?.year)
                .format('YYYY'),
            }
          : null,
        dealer: dataForPrint?.dealer || null,
        manager: dataForPrint?.manager || null,
        agreementNumber: dataForPrint?.agreementNumber,
        isPricingVisible: isPricingVisible,
        allServices,
        retailTotal,
        retailGST: retailGST?.toFixed(2),
        retailPST,
        retailGrand,
        createdAt: moment(
          convertDateByTimezone(dataForPrint?.createdAt, timezone)
        ).format('YYYY-MM-DD'),
        updatedAt: moment(
          convertDateByTimezone(dataForPrint?.updatedAt, timezone)
        ).format('YYYY-MM-DD'),
      };
      setPdfDownloadLoader(true);
      const response = await axios.post(
        PDF_URL,
        {
          // document: '3tLzMdT0utPZ1lnla8ZA',
          document: 'a1B0BFl3RqrArTZC0m0A', // test template
          apiKey: 'ISVXQ2Y-XIYUSOQ-VDJLDZA-JJ4NEPQ',
          format: 'pdf',
          data: payload,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.data && response.data?.data) {
        await downloadFile(
          response.data.data,
          `Registrant-${dataForPrint?.agreementNumber}`
        );
      }
      setPdfDownloadLoader(false);
    } catch (err) {
      setPdfDownloadLoader(false);
      console.log('err', err);
    }
  };

  const categoryOrder = {
    'MPP - Maintenance Protection Plan': 1,
    'APP - Appearance Protection Plan': 2,
    'Appearance Trust': 3,
    'Oil Change Program': 4,
    'Trust Care': 5,
    'Key Fob': 6,
    'Tire & Rim': 7,
    'Anti Theft': 8,
    'Brakes & Rotors': 9,
    'Trust Shield': 10,
    Ceramic: 11,
  };

  // Function to reset checkboxes for categories containing "Trust Shield"
  const resetCheckboxesForTrustShield = () => {
    // Only execute if NOT in edit mode (editReport is null)
    if (editReport === null) {
      serviceCatPrograms.forEach((category) => {
        if (category.categoryName.includes("Trust Shield")) {
          // Reset checkBoxesForTerms for this category
          setCheckBoxesForTerms((prevTerms) => {
            const updatedTerms = { ...prevTerms };
            Object.keys(updatedTerms).forEach((key) => {
              if (key.startsWith(`${category._id}-`)) {
                updatedTerms[key] = false;
              }
            });
            return updatedTerms;
          });

          // Reset checkBoxesForServices for this category
          setCheckBoxesForServices((prevServices) => {
            const updatedServices = { ...prevServices };
            Object.keys(updatedServices).forEach((key) => {
              if (key.startsWith(`${category._id}-`)) {
                updatedServices[key] = false;
              }
            });
            return updatedServices;
          });
        }
      });
    }
  };

  useEffect(() => {
    // When vehical.make changes, reset checkboxes for "Trust Shield" categories
    resetCheckboxesForTrustShield();
  }, [vehical.make]);

  return (
    <RegistrationContext.Provider value={{ setCheckBoxesForServices }}>
      <div className='row mb-50 p-4'>
        <div className='col-md-12 pb-4 d-flex justify-content-between w-100 align-items-center'>
          <div style={{ width: '40%' }}>
            <div
              className='d-flex justify-content-start align-items-center'
              style={{ width: '100%' }}
            >
              {role === USER_ROLE.ADMIN && (
                <div style={{ width: '50%' }}>
                  <CustomSelect
                    isSearch={true}
                    isDisabled={isDealerListLoader || editReport !== null}
                    loading={isDealerListLoader}
                    placeHolder={'Select Dealer'}
                    label='Dealer'
                    selectedValue={selectedDealerOption}
                    handleChange={(e) => setSelectedDealerOption(e)}
                    options={dealerOptions}
                    // error={
                    //   validationErrors?.category > 0 && "Category is required!"
                    // }
                  />
                </div>
              )}
              {(role === USER_ROLE.ADMIN || role === USER_ROLE.DEALER) && (
                <div style={{ width: '50%', paddingLeft: '10px' }}>
                  <CustomSelect
                    isClear={true}
                    isSearch={true}
                    isDisabled={isFetchManagerListLoader || editReport !== null}
                    loading={isFetchManagerListLoader}
                    placeHolder={'Select Manager'}
                    label='Manager'
                    selectedValue={selectedManager}
                    handleChange={(e) => setSelectedManager(e)}
                    options={managerListOptions}
                    // error={
                    //   validationErrors?.category > 0 && "Category is required!"
                    // }
                  />
                </div>
              )}
            </div>
          </div>

          <div style={{ width: '40%' }}>
            <div
              style={{ width: '100%' }}
              className='d-flex justify-content-end align-items-center'
            >
              {editReport !== null && (
                <div className='px-4'>
                  <CustomButton
                    icon={<FaPrint />}
                    showLoading={pdfDownloadLoader}
                    isDisabled={isFetchSerciceCatPrograms || pdfDownloadLoader}
                    cssClass='btn-effect-1 text-uppercase btn1'
                    label='Print Certificate'
                    clickHandler={generatePDF}
                    style={{ width: '220px' }}
                  />
                </div>
              )}
              <div className='toggle'>
                <div className='mainTOggle'>
                  {/* <h1>Simple CSS toggle</h1> */}
                  <h4 style={{ fontWeight: '600' }}>Display Pricing</h4>
                  <input
                    type='checkbox'
                    onChange={handleToggleChange}
                    className='toggle'
                    id='toggle'
                  />
                  <label htmlFor='toggle'>
                    <span className='on'></span>
                    <span className='off'></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Accordion defaultActiveKey='0'>
          <Accordion.Item eventKey='0' className='mb-3'>
            <Accordion.Header className='accord-Header dropBtn-after'>
              Customer Information
            </Accordion.Header>
            <Accordion.Body className='ac-Body px-sm-none'>
              <CustomerInformation
                gst={gst}
                pst={pst}
                setGst={setGst}
                setPst={setPst}
                isEditReport={editReport}
                error={customerError}
                setError={setCustomerError}
                customer={customer}
                setCustomer={setCustomer}
                disableTaxRatesChange={
                  (role !== USER_ROLE.STAFF.SERVICE_ADVISOR || role !== USER_ROLE.STAFF.TECHNICIAN) &&
                  moment().diff(moment(vehical.purchaseDate), 'days') <= 30
                    ? true
                    : false
                }
              />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Accordion defaultActiveKey='0'>
          <Accordion.Item eventKey='0'>
            <Accordion.Header className='accord-Header dropBtn-after'>
              Vehicle Details
            </Accordion.Header>
            <Accordion.Body className='ac-Body'>
              <DecodeVechicle
                isEditReport={editReport}
                vehical={vehical}
                setVehical={setVehical}
                error={vehicalError}
                setError={setVehicalError}
                vehicleVin={vehicleVin}
                setvehicleVin={setvehicleVin}
              />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <h3 className='pb-2 mt-3'>Services and Offers</h3>
        {selectedManager &&
        selectedManager?.value &&
        !isFetchSerciceCatPrograms ? (
          <>
            <div className='col-md-4 d-none'>
              <label className='Sercive-1 p-2'>INITIL TERMS</label>
              <div className='input-item'></div>
            </div>
            <div className='col-md-12'>
              <div className='input-item'>
                { serviceCatPrograms
                  .filter((serviceWithCatProgram) =>
                    vehical.isPowerSport
                      ? vehical.vehicalTypeNote === ""
                        ? serviceWithCatProgram.appliedVehicles[0].includes('powersports') 
                        : vehical.vehicalTypeNote === "UTV" 
                          ? serviceWithCatProgram.appliedVehicles[0].includes('powersports') && !serviceWithCatProgram.appliedVehicles[0].includes('ATV')// and UTV
                          : serviceWithCatProgram.appliedVehicles[0].includes('powersports') && !serviceWithCatProgram.appliedVehicles[0].includes('UTV')// and ATV
                      : serviceWithCatProgram.appliedVehicles[0].includes('automotive')
                  )
                  .sort(
                    (a, b) =>
                      a.displayOrder - b.displayOrder
                  )
                  .map((serviceWithCatProgram, index) => (
                    <Offers
                      handleInputServicesCatDetails={
                        handleInputServicesCatDetails
                      }
                      structuredData={structuredData}
                      vehical={vehical}
                      index={index}
                      key={index}
                      serviceTenureOptions={serviceTenureOptions}
                      serviceTierOptions={serviceTierOptions}
                      serviceWithCatProgram={serviceWithCatProgram}
                      isPricingVisible={isPricingVisible}
                      checkBoxesForTerms={checkBoxesForTerms}
                      setCheckBoxesForTerms={setCheckBoxesForTerms}
                      checkBoxesForServices={checkBoxesForServices}
                      setCheckBoxesForServices={setCheckBoxesForServices}
                      handleUnCheckedOtherTerms={handleUnCheckedOtherTerms}
                      handleCheckedServicesByTenure={
                        handleCheckedServicesByTenure
                      }
                    />
                  ))
                }

                <div className='ltn__my-properties-table table-responsive mobileTable'>
                  <ServicePricingSummary
                    isPricingVisible={isPricingVisible}
                    structuredData={structuredData}
                    gst={gst}
                    pst={pst}
                  />
                </div>
              </div>
            </div>
          </>
        ) : isFetchSerciceCatPrograms ? (
          <div className='d-flex justify-content-start align-items-center'>
            <div className='loader-container-claim'>
              <div className='loader-claim'></div>
            </div>
            {` Loading Services...`}
          </div>
        ) : (
          <p className='error-text'>Select Manager For Services.</p>
        )}
      </div>
      {editReport === null ? (
        <div className='d-flex justify-content-start align-items-center'>
          <CustomButton
            isDisabled={isSubmitApiLoader || isFetchSerciceCatPrograms}
            showLoading={isSubmitApiLoader}
            style={{
              padding: '15px 30px',
              backgroundColor: 'rgb(255, 90, 60)',
              color: '#FFF',
              marginBottom: '30px',
              marginLeft: '40px',
              fontSize: '16px',
            }}
            cssClass='btn-effect-1 text-uppercase btn1'
            clickHandler={() => {
              handleSubmit();
            }}
            label='Submit'
          />
          {/* <button
            className="btn theme-btn-1 btn-effect-1 text-uppercase btn1"
            style={{
              padding: "15px 30px",
              backgroundColor: "rgb(255, 90, 60)",
              color: "#FFF",
              marginBottom: "30px",
              fontSize: "16px",
            }}
            onClick={() => {
              setIsDraft(true);
              handledraft();
            }}
          >
            Save for Later (Draft)
          </button> */}
        </div>
      ) : (
        <>
          <div
            style={{
              padding: '15px 30px',
            }}
            className={classNames(
              'd-flex justify-content-between',
              (role !== USER_ROLE.STAFF.SERVICE_ADVISOR || role !== USER_ROLE.STAFF.TECHNICIAN) && 'flex-row-reverse'
            )}
          >
            {(role !== USER_ROLE.STAFF.SERVICE_ADVISOR || role !== USER_ROLE.STAFF.TECHNICIAN) && (
              <div>
                { (vehical.purchaseDate && moment().diff(moment(vehical.purchaseDate), 'days') > 30 ) 
                  ? role == USER_ROLE.ADMIN 
                    ? 
                      <CustomButton
                      isDisabled={isSubmitApiLoader || isFetchSerciceCatPrograms}
                      showLoading={isSubmitApiLoader}
                      style={{
                        padding: '15px 30px',
                        backgroundColor: 'rgb(255, 90, 60)',
                        color: '#FFF',
                        marginBottom: '30px',
                        marginLeft: '40px',
                        fontSize: '16px',
                      }}
                      label='Update'
                      cssClass='btn-effect-1 text-uppercase btn1'
                      clickHandler={() => {
                        if (selectedReason === '' || !selectedReason) {
                          toast.error('Select Update Reason');
                          return;
                        }
                        handleSubmit();
                      }}
                      />
                    : <div></div>
                  : <CustomButton
                    isDisabled={isSubmitApiLoader || isFetchSerciceCatPrograms}
                    showLoading={isSubmitApiLoader}
                    style={{
                      padding: '15px 30px',
                      backgroundColor: 'rgb(255, 90, 60)',
                      color: '#FFF',
                      marginBottom: '30px',
                      marginLeft: '40px',
                      fontSize: '16px',
                    }}
                    label='Update'
                    cssClass='btn-effect-1 text-uppercase btn1'
                    clickHandler={() => {
                      if (selectedReason === '' || !selectedReason) {
                        toast.error('Select Update Reason');
                        return;
                      }
                      handleSubmit();
                    }}
                    />
                }
              </div>
            )}
            <div className='d-flex  '>
              <button
                type='button'
                className='btn btn-backk theme-btn-1 btn-effect-1 text-uppercase btn1'
                style={{
                  backgroundColor: 'rgb(255, 90, 60)',
                  color: '#FFF',
                  fontSize: '14px',
                  height: '50px',
                  width: '120px',
                }}
                onClick={() => {
                  if (editReport !== null) {
                    setEditReport(null);
                  }
                  if (setShowEditRegistrants) {
                    setShowEditRegistrants(null);
                  }
                }}
              >
                <Icon icon='mdi:less-than' /> Back
              </button>
              {(role !== USER_ROLE.STAFF.SERVICE_ADVISOR || role !== USER_ROLE.STAFF.TECHNICIAN) && (
                <div>
                { (vehical.purchaseDate && moment().diff(moment(vehical.purchaseDate), 'days') > 30 )
                  ? role == USER_ROLE.ADMIN 
                    ? <select
                        className='form-select form-select-option nice-select '
                        style={{ height: '55px' }}
                        value={selectedReason}
                        onChange={(e) => setSelectedReason(e.target.value)}
                      >
                        <option value='' selected disabled>
                          Choose Reason
                        </option>
                        <option value='refund'>Refund</option>
                        <option value='customerChangedMind'>
                          Customer Changed Mind
                        </option>
                        <option value='dealCancelled'>Deal Cancelled</option>
                        <option value='duplicateEntry'>Duplicate Entry</option>
                        <option value='changedVehicle'>Changed Vehicle</option>
                        <option value='vehicleCancelled'>
                          Vehicle was Cancelled
                        </option>
                        <option value='updating_customer_fields'>
                          Updating Customer Fields
                        </option>
                        <option value='updating_service_fields'>
                          Updating Service Fields
                        </option>
                        <option value='updating_vehicle_details'>
                          Updating Vehicle Details
                        </option>
                      </select>
                    : <></>
                  : <select
                      className='form-select form-select-option nice-select '
                      style={{ height: '55px' }}
                      value={selectedReason}
                      onChange={(e) => setSelectedReason(e.target.value)}
                    >
                      <option value='' selected disabled>
                        Choose Reason
                      </option>
                      <option value='refund'>Refund</option>
                      <option value='customerChangedMind'>
                        Customer Changed Mind
                      </option>
                      <option value='dealCancelled'>Deal Cancelled</option>
                      <option value='duplicateEntry'>Duplicate Entry</option>
                      <option value='changedVehicle'>Changed Vehicle</option>
                      <option value='vehicleCancelled'>
                        Vehicle was Cancelled
                      </option>
                      <option value='updating_customer_fields'>
                        Updating Customer Fields
                      </option>
                      <option value='updating_service_fields'>
                        Updating Service Fields
                      </option>
                      <option value='updating_vehicle_details'>
                        Updating Vehicle Details
                      </option>
                    </select>
                }
                </div>
              )}
            </div>
          </div>
        </>
      )}

      {/* Add Registrant */}
      <Modal show={showmodal} className='confirmmodal'>
        <Modal.Body>
          <Icon
            icon='mdi:tick-circle'
            width='60'
            height='60'
            style={{ color: '#4A6543' }}
          />
          <h4>Thank You!</h4>
          <p>{`${
            editReport === null
              ? 'Report Submitted Successfully.'
              : 'Report Updated Successfully.'
          }`}</p>
          <div className='d-flex justify-content-center align-items-center '>
            <div>
              <CustomButton
                cssClass='text-uppercase d-flex justify-content-center align-items-center rounded space-x-2'
                variant='tertiary'
                label='Ok'
                clickHandler={() => {
                  if (editReport !== null) {
                    setEditReport(null);
                  }
                  if (setShowEditRegistrants) {
                    setShowEditRegistrants(null);
                  }
                  if (setCounter) {
                    setCounter((prev) => prev + 1);
                  }
                  setActiveTab('showAllRegistrants');
                  setShowmodal(false);
                }}
              />
            </div>
            <div>
              <CustomButton
                icon={<FaPrint />}
                showLoading={pdfDownloadLoader}
                isDisabled={isFetchSerciceCatPrograms || pdfDownloadLoader}
                cssClass='text-uppercase d-flex justify-content-center align-items-center rounded space-x-2'
                label='Print Certificate'
                variant='tertiary'
                clickHandler={generatePDF}
                style={{ width: '220px' }}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* Draft Report */}
      <Modal show={showmodalDraft} className='confirmmodal'>
        <Modal.Body>
          <Icon
            icon='mdi:tick-circle'
            width='60'
            height='60'
            style={{ color: '#4A6543' }}
          />
          <h4>Thank You!</h4>
          <p>Draft Saved Successfully.</p>
          <button onClick={() => setShowModalDraft(false)}>OK</button>
        </Modal.Body>
      </Modal>

      {/* {showPrint && ( */}
      {editReport !== null && (
        <PrintRegistration
          downloadRef={downloadRef}
          editReport={editReport}
          data={structuredData}
        />
      )}
      {/* )} */}
    </RegistrationContext.Provider>
  );
};

export default AddRegistrants;
