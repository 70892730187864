import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { FaEdit } from "react-icons/fa";
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";
import secureLocalStorage from "react-secure-storage";
import AddRegistrants from "../AddRegistrants";
import CustomButton from "../../../common/Button/CustomButton";
import useDealer from "../../../../Hooks/useDealer";
import { USER_ROLE } from "../../../../utils/constant";
import {
  capitalizeFirstLetter,
  convertDateByTimezone,
} from "../../../../utils/utils";
import { apiGetRequest, apiDeleteRequest } from "../../../../helpers/Requests";
import {
  URL_ADMIN_GET_MANAGER_BY_DEALER_ID,
  URL_DEALER_GET_MANAGER_BY_ID,
  URL_ADMIN_DELETE_REPORT,
} from "../../../../service/URL";
import parseError from "../../../../utils/ErrorParse";
import Style from "./showAllReg.module.css";
import moment from "moment";
import { CgNotes } from "react-icons/cg";
import ReportLog from "../../../../page/reportLog";
import { Modal } from "react-bootstrap";
import Loader from "../../../common/Loader/Loader";
import AuthContext from "../../../../helpers/authContext";
import ConfirmationModal from "../../../common/Modal/ConfirmationModal";


const ShowAllReg = ({
  dealerObj,
  managerObj,
  setShowEditRegistrants,
  showEditRegistrants,
  setActiveTab,
}) => {
  const { timezone } = useContext(AuthContext);

  const { dealersData = [] } = useDealer();
  const [managerOptions, setManagerOptions] = useState([]);
  const [selectedManagerOption, setSelectedManagerOption] = useState(null);
  const [selectedDealerOption, setSelectedDealerOption] = useState(null);
  const [dealerOptions, setDealerOptions] = useState([]);
  const [isFetchManagerByDealerLoader, setIsFetchManagerByDealerLoader] =
    useState(false);
  const [search, setSearch] = useState(null);

  const [isConfirmDeleteRegistrant, setIsConfirmDeleteRegistrant] =
    useState(null);

  const userId = JSON.parse(secureLocalStorage.getItem("UserID"));

  const [selectedReportLogShow, setSelectedReportShowLog] = useState(null);

  useEffect(() => {
    if (role === USER_ROLE.DEALER) {
      handleDealerChange(userId);
    }
    setSelectedManagerOption(null);
    setSelectedDealerOption(null);
    setDealerOptions([]);
    setManagerOptions([]);
    setRegistrantDeleted(false);
  }, []);

  useEffect(() => {
    if (
      selectedDealerOption &&
      selectedDealerOption !== "all" &&
      role === USER_ROLE.ADMIN
    ) {
      handleDealerChange(selectedDealerOption);
      setSelectedManagerOption(null);
      setManagerOptions([]);
      handleSearch();
    } else if (selectedDealerOption && selectedDealerOption === "all") {
      setManagerOptions([]);
      setSelectedManagerOption(null);
      handleSearch();
    }
  }, [selectedDealerOption]);

  const handleDealerChange = async (dealerId) => {
    try {
      // const id = dealerId !== null ? dealerId : selectedDealerOption;
      setIsFetchManagerByDealerLoader(true);
      const response = await apiGetRequest(
        `${
          role === USER_ROLE.ADMIN
            ? `${URL_ADMIN_GET_MANAGER_BY_DEALER_ID}/${dealerId}`
            : URL_DEALER_GET_MANAGER_BY_ID
        }`
      );
      if (response) {
        setManagerOptions(
          response.data?.map((x) => ({
            label:
              x?.firstName && x.firstName?.length > 0
                ? `${x.firstName} ${x.lastName}`
                : x?.name || "",
            value: x._id,
          }))
        );
      }
      setIsFetchManagerByDealerLoader(false);
    } catch (err) {
      setIsFetchManagerByDealerLoader(false);
      setManagerOptions([]);
      console.log("err");
    }
  };

  useEffect(() => {
    if (dealersData?.length > 0) {
      setDealerOptions(
        dealersData?.map((x) => ({
          ...x,
          label:
            // x?.firstName && x.firstName?.length > 0
            //   ? `${x.firstName} ${x.lastName}`
            //   : x?.name || "",
            x?.name || "",
          value: x._id,
        }))
      );
    }
  }, [dealersData]);

  useEffect(() => {
    if (search === null) return;
    const timeOutId = setTimeout(() => handleSearch(), 500);
    return () => clearTimeout(timeOutId);
  }, [search]);

  const [data, setData] = useState([]);
  const [counter, setCounter] = useState(0);
  const [registrantDeleted, setRegistrantDeleted] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const apiUrl = process.env.REACT_APP_API_URL;
  const token = JSON.parse(secureLocalStorage.getItem("Token"));
  const [role] = useState(JSON.parse(secureLocalStorage.getItem("Role")));

  useEffect(() => {
    // if (role !== USER_ROLE.ADMIN) {
    if (counter !== 0) {
      handleSearch();
    }
    // }
  }, [counter]);

  const fetchData = async (filter = {}) => {
    try {
      const roleApi =
        role === "Technician" ||
        role === "Sales Advisor" ||
        role === "Service Advisor"
          ? "staff"
          : role;
      setIsLoading(true);
      const getRegistrants = `${apiUrl}/${roleApi?.toLowerCase()}/reports`;
      axios
        .post(getRegistrants, filter, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          const usersData = response.data;
          setData(usersData);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log("Error:", error);
          setIsLoading(false);
        });
    } catch (err) {
      console.log("err while getting reports:", err);
    }
  };

  const [reportData, setReportData] = useState(null);

  const [isGetReportLoader, setIsGetReportLoader] = useState(false);

  const fetchReportById = async (customerId, reportId, dealerId, managerId) => {
    const roleApi =
      role === "Technician" ||
      role === "Sales Advisor" ||
      role === "Service Advisor"
        ? "staff"
        : role;

    let url = `${apiUrl}/${roleApi?.toLowerCase()}/report/${reportId}`;

    // if (role === USER_ROLE.ADMIN) {
    //   url = `${url}?dealerId=${dealerId}&managerId=${managerId}`;
    // }

    const getReportUrl = url;
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      setIsGetReportLoader(reportId);
      const response = await axios.get(getReportUrl, { headers });
      const data = response.data;
      
      setIsGetReportLoader(null);
      setReportData(data);
      setShowEditRegistrants(true);
    } catch (error) {
      setIsGetReportLoader(null);
      const errorMessage = parseError(error);
      toast.error(errorMessage ?? "Something went wrong!");
      console.log("error while getting customer Report:  ", error);
    }
  };

  const handleSearch = () => {
    let searchObj = {};
    if (search !== null && search !== undefined) {
      Object.assign(searchObj, {
        search,
      });
    }

    if (role === USER_ROLE.ADMIN) {
      if (selectedDealerOption && selectedDealerOption !== "all") {
        Object.assign(searchObj, {
          dealer_id: selectedDealerOption,
        });
      }
      if (selectedManagerOption && selectedManagerOption !== "all") {
        Object.assign(searchObj, {
          manager_id: selectedManagerOption,
        });
      }
    } else if (role === USER_ROLE.DEALER) {
      if (selectedManagerOption && selectedManagerOption !== "all") {
        Object.assign(searchObj, {
          manager_id: selectedManagerOption,
        });
      }
    }
    fetchData(searchObj);
  };

  useEffect(() => {
    handleSearch();
  }, [selectedManagerOption]);

  // Delete registrant handler 
  const deleteRegistrantHandler = async (id) => {
    try {
      const response = await apiDeleteRequest(URL_ADMIN_DELETE_REPORT, {
        report_id: id.id,
      });
      setRegistrantDeleted(true);
      if (response.data?.message) {
        toast.success("Registrant Deleted Successfully.");
      }
    } catch (err) {
      toast.error(err ?? "Something Went Wrong");
    }
  };

  useEffect(() => {
    handleSearch();
    setRegistrantDeleted(false);    
  }, [registrantDeleted]);    


  return (
    <div>
      {/* Search portion delete from heree */}
      <div className="ltn__form-box px-4 pt-4">
        <div className="row">
          <h3 className="pb-3">
            {showEditRegistrants && reportData
              ? "Registration"
              : "All Registrations"}
          </h3>
          {showEditRegistrants && reportData ? null : (
            <>
              {role === USER_ROLE.ADMIN && (
                <>
                  <div className="col-md-6 px-0 pr-1">
                    <label className="pb-2">Dealer</label>
                    <div className="input-item">
                      <select
                        isDisabled={true}
                        className="nice-select"
                        value={selectedDealerOption}
                        onChange={(e) => {
                          setSelectedDealerOption(e.target.value);
                        }}
                      >
                        <option value="all">All Dealer</option>
                        {dealerOptions.map((item, index) => (
                          <option key={index} value={item.value}>
                            {capitalizeFirstLetter(item.label)}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </>
              )}
              {(role === USER_ROLE.ADMIN || role === USER_ROLE.DEALER) && (
                <div className="col-md-6 px-0">
                  <label className="pb-2">Manager</label>
                  <div className="input-item">
                    <select
                      className="nice-select"
                      value={selectedManagerOption}
                      onChange={(e) => {
                        setSelectedManagerOption(e.target.value);
                      }}
                    >
                      <option value="all">All Manager</option>
                      {managerOptions.map((item, index) => (
                        <option key={index} value={item.value}>
                          {capitalizeFirstLetter(item.label)}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
              <div className={"col-12 px-0"}>
                <div className="search-input">
                  <i class="fa fa-search" aria-hidden="true"></i>
                  <input
                    // disabled={isLoading}
                    className=""
                    id="search"
                    type="text"
                    name="search"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder="Search by Agreement Number, Vehicle VIN, Customer First Name, Last Name, Email and Phone..."
                  />
                </div>
              </div>
            </>
          )}
        </div>
        {/* {showEditRegistrants && reportData ? null : (
          <>
            {(role === USER_ROLE.ADMIN || role === USER_ROLE.DEALER) && (
              <div className="px-2" style={{ display: "flex" }}>
                <CustomButton
                  showLoading={isLoading}
                  isDisabled={isLoading || isFetchManagerByDealerLoader}
                  label="Search"
                  type="button"
                  clickHandler={handleSearch}
                  cssClass="btn-effect-1 text-uppercase"
                />
              </div>
            )}
          </>
        )} */}
      </div>
      <div>
        {showEditRegistrants && reportData ? (
          <>
            <AddRegistrants
              dealerObj={dealerObj}
              managerObj={managerObj}
              setCounter={setCounter}
              setShowEditRegistrants={() => {
                setShowEditRegistrants(null);
                setReportData(null);
              }}
              setEditReport={setReportData}
              editReport={reportData}
              setActiveTab={setActiveTab}
              isReadOnly={true}
            />
            {/* <EditRegistrants setEditReport={setReportData}/> */}
          </>
        ) : (
          <>
            <div className="ltn__myaccount-tab-content-inner px-4 pt-4">
              <div className="ltn__my-properties-table table-responsive">
                <div className="mobileTable">
                  <table className="showreg-table">
                    <>
                      <thead>
                        <tr>
                          <th scope="col">Agreement Number</th>
                          {role === USER_ROLE.ADMIN && (
                            <>
                              <th scope="col">Dealer</th>
                              <th scope="col">Manager</th>
                            </>
                          )}
                          <th scope="col">Customer</th>
                          {/* <th scope="col">First Name</th> */}
                          {/* <th scope="col">Last Name</th> */}
                          <th scope="col">Customer Email</th>
                          <th scope="col">Vehicle</th>
                          <th scope="col">Purchase/Lease Date</th>
                          <th scope="col">Status</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      {isLoading ? (
                        <div className="py-4">
                          <Loader cssClass={"text-black"} isSmallView={true} />
                        </div>
                      ) : (
                        <tbody>
                          <>
                            {data && data.length > 0 ? (
                              data?.map((row) => (
                                <tr key={row._id}>
                                  <td>{row.agreementNumber}</td>
                                  {role === USER_ROLE.ADMIN && (
                                    <>
                                      <td>
                                        {capitalizeFirstLetter(
                                          `${row.dealer?.name}`
                                        )}
                                      </td>
                                      <td>
                                        {capitalizeFirstLetter(
                                          `${row.manager?.firstName} ${row.manager?.lastName}`
                                        )}
                                      </td>
                                    </>
                                  )}
                                  <td>{row.customer.firstName + " " + row.customer.lastName}</td>
                                  {/* <td>{row.customer.lastName}</td> */}
                                  <td>{row.customer.email}</td>
                                  <td>
                                    {`${moment
                                      .utc(row.customer.vehical?.year)
                                      .format("YYYY")} ${
                                      row.customer.vehical?.make
                                    } ${row.customer.vehical?.modal}`}
                                  </td>
                                  <td>
                                    {moment(
                                      convertDateByTimezone(
                                        row.customer.vehical?.purchaseDate,
                                        timezone
                                      )
                                    )?.format("MMM DD, YYYY") || "-"}
                                  </td>
                                  <td>
                                    {row.status ? (
                                      <Icon
                                        icon="oi:check"
                                        style={{ color: "#65B56B" }}
                                      />
                                    ) : (
                                      <Icon
                                        icon="gridicons:cross"
                                        style={{ color: "#ff0000" }}
                                      />
                                    )}
                                  </td>
                                  <td>
                                    <div className="showViewAll">
                                      <>
                                        <CustomButton
                                          cssClass="btn-effect-1 text-uppercase btn1"
                                          style={{
                                            // padding: "5px 10px",
                                            // backgroundColor: "rgb(255, 90, 60)",
                                            color: "#FFF",
                                            // fontSize: "0.8rem",
                                            borderRadius: "4px",
                                          }}
                                          icon={moment(
                                            convertDateByTimezone(
                                              row.customer.vehical?.purchaseDate,
                                              timezone
                                            )
                                          ).isBefore(
                                            moment().subtract(30, 'days')
                                          ) && role !== USER_ROLE.ADMIN ? '' : <FaEdit />}
                                          isDisabled={
                                            isGetReportLoader === row._id
                                          }
                                          showLoading={
                                            isGetReportLoader === row._id
                                          }
                                          clickHandler={() => {
                                            fetchReportById(
                                              row.customer._id,
                                              row._id,
                                              row.dealer._id,
                                              row.manager._id
                                            );
                                          }}
                                          label={ moment(
                                            convertDateByTimezone(
                                              row.customer.vehical?.purchaseDate,
                                              timezone
                                            )
                                          ).isBefore(
                                            moment().subtract(30, 'days')
                                          ) && role !== USER_ROLE.ADMIN ? "Detail" : "Edit"}
                                        />

                                        {role !==
                                          USER_ROLE.STAFF.SERVICE_ADVISOR && (
                                          <CustomButton
                                            cssClass="btn-effect-1 text-uppercase btn1"
                                            style={{
                                              color: "#FFF",
                                              borderRadius: "4px",
                                            }}
                                            icon={<CgNotes />}
                                            clickHandler={() => {
                                              setSelectedReportShowLog(row._id);
                                            }}
                                            label="Logs"
                                          />
                                        )}

                                        {role === USER_ROLE.ADMIN && (
                                          <CustomButton
                                            cssClass="btn-effect-1 text-uppercase btn1"
                                            // style={{
                                            //   color: "#FFF",
                                            //   borderRadius: "4px",
                                            // }}
                                            variant={"danger"}
                                            icon={<CgNotes />}
                                            clickHandler={()=>{
                                              setIsConfirmDeleteRegistrant({id:row._id, agrnum: row.agreementNumber});
                                            }}
                                            label="Del"
                                          />
                                        )}
                                        
                                        {/* <button
                                          onClick={() => {
                                            setModalShow(true);
                                            showOverview(row._id);
                                          }}
                                          className="btn theme-btn-1 btn-effect-1 text-uppercase btn1"
                                          style={{
                                            padding: "5px 10px",
                                            backgroundColor: "rgb(255, 90, 60)",
                                            color: "#FFF",
                                            fontSize: "0.8rem",
                                            borderRadius: "4px",
                                          }}
                                        >
                                          <Icon
                                            icon="gravity-ui:box"
                                            color="white"
                                          />
                                          View All
                                        </button> */}
                                      </>
                                    </div>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="9">No Data Found!</td>
                              </tr>
                            )}
                          </>
                        </tbody>
                      )}
                    </>
                  </table>
                </div>
              </div>
              <div className="ltn__pagination-area text-center"></div>
            </div>
          </>
        )}
      </div>

      {isConfirmDeleteRegistrant !== null && (
        <ConfirmationModal
          isOpen={isConfirmDeleteRegistrant !== null}
          confirmationStatus={(e) => {
            if (e === true) {
              deleteRegistrantHandler(isConfirmDeleteRegistrant);
            }
            setIsConfirmDeleteRegistrant(null);
          }}
          title={"Delete Registrant Confirmation"}
          heading={`Are you sure to delete this registrant: ${isConfirmDeleteRegistrant.agrnum}?`}
        />
      )}

      {selectedReportLogShow && selectedReportLogShow !== null && (
        <Modal
          className={Style.reportlogModal}
          size="lg"
          show={selectedReportLogShow !== null}
          onHide={() => {
            setSelectedReportShowLog(null);
          }}
        >
          <Modal.Header
            style={{
              borderBottom:
                "var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);",
            }}
            closeButton
          >
            <Modal.Title>Report Log </Modal.Title>
          </Modal.Header>
          <Modal.Body className="md-body" style={{ height: "82vh" }}>
            <div>
              <ReportLog selectedReportLogShow={selectedReportLogShow} />
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default ShowAllReg;
